import React, { Suspense } from "react";

const HomePage = React.lazy(() => import("../view/create-1.jsx"));

const Home1 = () => {
  return (
    <Suspense fallback={null}>
      <HomePage />
    </Suspense>
  );
};

export default Home1;
