import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./services/store";
import Home from "./routes/index.jsx";
import Create1 from "./routes/create-1.jsx";
import VaultUpload from "./routes/vault.jsx";
import Vault from "./routes/vaultList.jsx";
import Video from "./routes/video.jsx";
import Editor from "./routes/editor.jsx";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home1 from "./routes/create-2.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/text-video/create",
    element: <Create1 />,
  },
  {
    path: "/text-video/prompt",
    element: <Home1 />,
  },
  {
    path: "/vault",
    element: <Vault />,
  },
  {
    path: "/vault/upload",
    element: <VaultUpload />,
  },
  {
    path: "/video",
    element: <Video />,
  },
  {
    path: "/text-video/editor",
    element: <Editor />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
