import React, { Suspense } from "react";

const HomePage = React.lazy(() => import("../view/home.jsx"));

const Home = () => {
  return (
    <div className="relative w-[100vw] h-[100vh] relative">
      <div className="w-[100vw] h-[100vh] absolute z-0">
        <video src="/home.webm" className="w-[100%] h-[100vh] object-cover" autoPlay muted></video>
      </div>
      <div className="w-[100vw] h-[100vh] absolute z-10">
        <Suspense fallback={null}>
          <HomePage />
        </Suspense>
      </div>
    </div>
  );
};

export default Home;
