import axios from "axios";
import React, { useEffect, useState } from "react";

const Video = () => {
  const [check, setCheck] = useState(false);
  const [src, setSrc] = useState(null);
  useEffect(() => {
    if (!check) {
      setCheck(true);
    }
  });

  return (
    // <>{src && <video className="w-[100vw] h-[100vh]" controls src={src} />}</>
    // <video
    //   controls
    //   src="https://pctcjltyiapmefadxnmx.supabase.co/storage/v1/object/public/assets/generatedVideos/1699535203140000.mp4"
    //   className="w-[100vw] h-[100vh]"
    // ></video>
    <video className="w-[100vw] h-[100vh]" autoPlay controls>
      <source
        src="https://pctcjltyiapmefadxnmx.supabase.co/storage/v1/object/public/assets/generatedVideos/1699535203140000.mp4"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  );
};

export default Video;
